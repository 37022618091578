.custom-div-bg {
  background-color: var(--bg_white);
  border-radius: var(--br_rs);
  box-shadow: var(--shadow_3);
}

.TablebtnDiv {
  flex-direction: row-reverse;
  display: flex;
}

.TableAction {
  background-color: var(--green);
  border: 2px solid var(--t_green);
  box-shadow: var(--shadow_3);
  color: var(--white);
  border-radius: 5px;
  padding: 5px 10px 0;
}

.hrmr {
  margin: 0 5px;
}

.tableHead {
  padding: 16px;
}

.tablesearch {
  width: 250px;
}

.tableActions {
  width: 800px;
}

.tableTitle {
  color: #000;
  font-family: var(--font);
  letter-spacing: .15px;
  flex-basis: 100%;
  grid-area: auto;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.filterbox {
  cursor: pointer;
  margin-left: 15px;
  font-size: 25px;
  position: relative;
}

.filteroptions {
  border: none;
  border-radius: 5px;
  width: 0;
  height: 0;
  padding: 0;
  transition: all .3s;
  position: absolute;
  top: 25px;
  right: 0;
  overflow: hidden;
}

.filterbox:hover > .filteroptions {
  box-shadow: var(--shadow_3);
  background-color: #fff;
  border: 1.5px solid #b2b2b2;
  width: 100px;
  height: 65px;
  padding: 5px;
}

.filteroption {
  font-family: var(--font);
  font-size: 14px;
}

.optiondiv {
  border-radius: 2px;
  padding: 2px 2px 2px 5px;
}

.optiondiv:hover, .optionactive {
  background-color: var(--tmc);
  color: #fff;
}

.selectedNotif {
  font-family: var(--font);
  color: #fff;
  border: 1px solid var(--tmc);
  background-color: var(--tmc);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 1px;
  font-size: 8px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  right: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.filterreset:hover {
  animation: .8s linear infinite spin;
  transform: rotate(-360deg);
}

.filterreset:active {
  color: var(--disablec);
}

.cusTable {
  overflow: auto;
}

.Table {
  border-collapse: collapse;
}

.TheadRow th {
  text-align: left;
  font-family: var(--font);
  padding: 16px;
}

.thRight {
  text-align: right;
}

.thCenter {
  text-align: center;
}

.TRows {
  cursor: pointer;
}

.TRows:hover {
  background-color: var(--tmc_bbg);
}

.TRows th {
  font-family: var(--font);
  font-size: 16px;
}

.TRows td {
  border: 0;
  border-top: 1px solid var(--hrc);
  font-family: var(--font);
  padding: 16px;
  font-size: 14px;
}

.TableFooter {
  border-top: 1px solid var(--hrc);
  padding: 16px;
}

.Tfooter-right {
  font-family: var(--font);
  font-size: 14px;
}

.pageCount {
  font-weight: bold;
}

.TableArrows {
  margin-left: 15px;
}

.TableArrow {
  color: var(--tmc);
  cursor: pointer;
  margin: 0 8px 0 0;
  font-size: 30px;
}

.TableArrow:active, .tableIconbtn:active {
  color: var(--tmc_bg);
}

.tableArrowDisable {
  color: var(--disablec);
  cursor: not-allowed;
}

.tableIconbtn {
  color: var(--tmc);
  margin-right: 15px;
  font-size: 30px;
}

.SortingArrow {
  color: var(--disablec);
  font-size: 16px;
  transition: all .3s;
  transform: rotate(180deg);
}

.toggle-checkbox {
  z-index: 9;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(2);
}

.toggle-checkbox:checked ~ .SortingArrow {
  color: #242833;
  font-size: 16px;
  transform: rotate(0);
}

/*# sourceMappingURL=index.8fd91034.css.map */
