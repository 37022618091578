.custom-div-bg{
    background-color: var(--bg_white);
    border-radius: var(--br_rs);
    box-shadow: var(--shadow_3);
}

.TablebtnDiv {
    display: flex;
    flex-direction: row-reverse;
}

.TableAction {
    background-color: var(--green);
    padding: 5px 10px 0px;
    border-radius: 5px;
    border: 2px solid var(--t_green);
    box-shadow: var(--shadow_3);
    color: var(--white);
}

/* custom table */
.hrmr{
    margin: 0 5px;
}
.tableHead {
    padding: 16px;
}
.tablesearch{
    width: 250px;
}
.tableActions{
    width: 800px;
}
.tableTitle {
    color: #000000;
    flex-basis: 100%;
    font-family: var(--font);
    font-size: 20px;
    font-weight: 700;
    grid-area: auto;
    letter-spacing: 0.15px;
    line-height: 32px;
}
.filterbox{
    position: relative;
    font-size: 25px;
    cursor: pointer;
    margin-left: 15px;
}
.filteroptions{
    position: absolute;
    top: 25px;
    right: 0px;
    border: none;
    border-radius: 5px;
    padding: 0px;
    overflow: hidden;
    width: 0;
    height: 0;
    transition: 0.3s;
}
.filterbox:hover > .filteroptions{
    border:1.5px solid #b2b2b2;
    width: 100px;
    height: 65px;
    background-color: #fff;
    box-shadow: var(--shadow_3);
    padding: 5px;
}
.filteroption{
    font-family: var(--font);
    font-size: 14px;
}
.optiondiv{
    border-radius: 2px;
    padding: 2px 2px 2px 5px;
}
.optiondiv:hover{
    background-color: var(--tmc);
    color: #ffffff;
}
.optionactive{
    background-color: var(--tmc);
    color: #ffffff;
}
.selectedNotif{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 12px;
    height: 12px;
    padding: 1px;
    font-family: var(--font);
    font-size: 8px;
    font-weight: bold;
    color: #fff;
    border-radius: 50%;
    border: 1px solid var(--tmc);
    background-color: var(--tmc);
}

/* this animation to rotate refresh btn */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
  }
.filterreset:hover{
    transform: rotate(-360deg);
    animation: spin 0.8s linear infinite;
}
.filterreset:active{
    color: var(--disablec);
}

/* inside table */
.cusTable{
    overflow: auto;
}
.Table{
    border-collapse: collapse;
}
.TheadRow th{
    text-align: left;
    font-family: var(--font);
    padding: 16px;
}
.thRight{
    text-align: right;
}
.thCenter{
    text-align: center;
}

.TRows{
    cursor: pointer;
}
.TRows:hover{
    background-color: var(--tmc_bbg);
}
.TRows th{
    font-family: var(--font);
    font-size: 16px;
}
.TRows td{
    border: 0px;
    border-top: 1px solid var(--hrc);
    font-family: var(--font);
    font-size: 14px;
    padding: 16px;
}

/* table footer */
.TableFooter{
    padding: 16px;
    border-top: 1px solid var(--hrc);
}
.Tfooter-right{
    font-family: var(--font);
    font-size: 14px;
}
.pageCount{
    font-weight: bold;
}
.TableArrows{
    margin-left: 15px;
}
.TableArrow{
    color: var(--tmc);
    font-size: 30px;
    cursor: pointer;
    margin: 0 8px 0 0;
}
.TableArrow:active, .tableIconbtn:active {
    color: var(--tmc_bg);
  }
.tableArrowDisable{
    color: var(--disablec);
    cursor:not-allowed;
}
.tableIconbtn{
    margin-right: 15px;
    color: var(--tmc);
    font-size: 30px;
}

/* sorting actions */
.SortingArrow{
    font-size: 16px;
    transform: rotate(180deg);
    color: var(--disablec);
    transition: 0.3s;
}

.toggle-checkbox{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    cursor: pointer;
    transform: scale(2);
}
.toggle-checkbox:checked ~ .SortingArrow {
    font-size: 16px;
    transform: rotate(0deg);
    color: #242833;
  }